import React, { useEffect, useState } from 'react'
import BlogCard from '../components/blogs/BlogCard'
import { BallTriangle } from 'react-loader-spinner';

const BlogPage = () => {
    const [blogData, setBlogData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true)
            const response = await fetch('/blog-data.json');
            const data = await response.json();
            setBlogData(data);
            setIsLoading(false);
        }
        fetchData();
    },[])

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (isLoading) {
        return (
            <div className='md:py-14 py-10 md:px-32 px-10 min-h-[50vh] flex justify-center items-center' >
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#453C3C"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        )
    }

    return (
        <div className='md:px-20 px-10 py-20 bg-[#EBE7E7]'>
            <div className="w-full grid grid-cols-1 gap-16 md:grid-cols-3">
                {blogData.map((blog) => {
                    return <BlogCard data={blog} />
                })}
            </div>
        </div>
    )
}

export default BlogPage