import React from "react";
import { FaInstagram, FaFacebook, FaYoutube, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

const links = [
  {
    title: "Home",
    url: "/#",
  },
  {
    title: "About us",
    url: "/about-us",
  },
  {
    title: "Blogs",
    url: "/blogs",
  },
  {
    title: "FAQs",
    url: "/faq",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
  // {
  //   title: "Privacy Policy",
  //   url: "/privacy-policy",
  // },
  {
    title: "Terms & Conditions",
    url: "/terms-and-conditions",
  },
  {
    title: "Cancellation & Refund",
    url: "/cancellion-and-refund-policy",
  },
  {
    title: "Shipping & Delivery",
    url: "/shipping-policy",
  }
];
const socialLinks = [
  {
    title: "Instagram",
    url: "https://www.instagram.com/theemoteapp",
    icon: <FaInstagram size={20} />,
  },
  {
    title: "Facebook",
    url: "https://www.facebook.com/theemoteapp",
    icon: <FaFacebook size={20} />,
  },
  {
    title: "X",
    url: "https://www.twitter.com/theemoteapp",
    icon: <FaXTwitter size={20} />,
  },
  {
    title: "LinkedIn",
    url: "https://www.linkedin.com/company/theemoteapp/",
    icon: <FaLinkedin size={20} />,
  },
];

const Footer = () => {
  return (
    <div className="w-full bg-[#453c3c] h-auto px-8 py-20 flex text-white md:flex-row flex-col" id="contacts">
      <div className=" md:px-24 flex flex-col justify-between">
        <div className="logo flex flex-col items-start gap[1px]">
          <img src="/logo.png" alt="logo" className="my-4" />
          <p>Unleash your true self.</p>
          <p>Start your journey with Emote</p>
        </div>
        <p>Copyright © 2024 Emoteapp. All Rights Reserved</p>
      </div>
      <div className="quick-links  md:px-24 flex flex-col items-start md:gap-7 gap-3 mt-7 md:mt-0">
        <h1 className="font-bold">Quick Links</h1>
        <ul>
          {links.map((item, index) => {
            return (
              <li key={index} className="text-start py-[1px]">
                <a href={item.url}>{item.title}</a>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="social  md:px-24 flex flex-col items-start md:gap-7 gap-3 mt-7 md:mt-0">
        <h1 className="font-bold">Follow Us</h1>
        <div className="icons flex flex-col items-start gap-3">
          {socialLinks.map((item, index) => {
            return (
              <a key={index} href={item.url} className=" ">
                <div className="flex gap-2 items-center justify-center">
                  {item.icon}
                  <p>{item.title}</p>
                </div>
              </a>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Footer;
