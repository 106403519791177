import React from "react";
import EmoteCard from "./EmoteCard";
import Heading from "../heading/Heading";

const Emotes = () => {
  return (
    <div className="flex flex-col gap-16 my-16 px-12 md:px-32 mx-auto">
      <Heading text="Why Emote?" />
      <div className="w-full flex flex-col justify-between gap-16 lg:flex-row">
        <EmoteCard image={'/images/emote1.svg'} text={"Anonymity guaranteed"}/>
        <EmoteCard image={'/images/emote2.svg'} text={"Verified Listeners"}/>
        <EmoteCard image={'/images/emote3.png'} text={"Choose your mode: Chat, Call or video call"}/>
        <EmoteCard image={'/images/emote4.svg'} text={"24/7 availability"}/>
      </div>
    </div>
  );
};

export default Emotes;
