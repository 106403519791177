import React from "react";

const Hero = () => {
  return (
    <div className="bg-white" id="home">
      <div className="bg-[#453C3C] md:rounded-br-[15rem] rounded-br-[7rem] grid grid-cols-1 md:grid-cols-2 p-6 md:p-20 gap-12 md:gap-0 w-full">
        <div className="flex justify-center ">
          <div className="w-10/12 md:w-7/12">
            <h1 className="text-left text-white text-3xl md:text-6xl font-bold leading-[110%]">
              Feeling Lost?
              <br /> Share your <br /> feelings here!
            </h1>
            <h4 className="text-left text-[#FFFBFB] mt-6 md:mt-12">
              Your all-in-one solution for support and understanding. Share
              everything anonymously, be yourself, express, and feel better.
            </h4>
            <button className="text-lg font-medium border-2  border-[white] bg-[#1e1e1e] text-white w-fit rounded-2xl px-6 py-2 mt-6 md:mt-12">
              App Coming Soon
            </button>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <img src="/heart-br-1.png" className="w-[50%]" />
        </div>
      </div>
    </div>
  );
};

export default Hero;
