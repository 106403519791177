import React from 'react'
import Hero from '../components/Hero'
import Stats from '../components/Stats'
import EmotePara from '../components/emote_para/EmotePara'
import Emotes from '../components/emotes/Emotes'
import Blogs from '../components/blogs/Blogs'
import Faq from '../components/Faq'

const Landing = () => {
    return (
        <div>
            <Hero />
            <Stats />
            <div className='mx-auto'>
                <EmotePara />
                <Emotes />
                <Blogs />
            </div>
            <Faq />
        </div>
    )
}

export default Landing