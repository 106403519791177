import React, { useState } from "react";
import { faqData } from "../constants/faq";
import CollapsableFAQ from "./faq/Collapsable";
import { Link } from "react-router-dom";

const Faq = () => {
  const data = faqData;
  const [currentOpen, setCurrentOpen] = useState(null);
  return (
    <div className="flex w-full py-10 px-6 md:px-24 gap-4 flex-col md:flex-row" id="faqs">
      <div className="title w-full md:w-[30%] flex flex-col items-center justify-start">
        <h1 className="text-[50px] font-semibold text-center md:text-left">Frequently asked questions</h1>
        <img src="/faq.png" alt="faq" className="w-7/12" />
      </div>
      <div className="content w-full md:w-[70%] flex flex-col gap-6 items-start">
        {data.map((item, index) => {
          return (
            <CollapsableFAQ data={item} index={index} open={currentOpen} changeOpen={setCurrentOpen} />
          );
        })}
        <Link to="/faq">
          <button className='text-lg font-medium border-2 border-[#1e1e1e] w-fit rounded-2xl px-6 py-2'>
            Read more
          </button>
        </Link>
      </div>

    </div>
  );
};

export default Faq;
