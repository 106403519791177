import React from 'react'
import Heading from '../heading/Heading'
import BlogCarousel from './BlogCarousel'
import { Link } from 'react-router-dom'

const Blogs = () => {
  return (
    <div className="flex flex-col gap-16 py-16 w-full mx-auto border-y-2  border-[#453C3C]" id='blogs'>
      <Heading text="Blogs" />
      <div className='w-full'>
        <BlogCarousel />
      </div>
      <Link to="/blogs" className='text-lg font-medium border-2 border-[#1e1e1e] w-fit mx-auto rounded-2xl px-6 py-2'>
        Read more
      </Link>
    </div>
  )
}

export default Blogs