import { Routes, Route, Outlet, Link } from "react-router-dom";
import Landing from "./pages/Landing";
import FAQPage from "./pages/FAQPage";
import Navbar from "./components/navbar";
import Footer from "./components/Footer";
import PolicyWrapper from "./pages/PolicyWrapper";
import { cancellationPolicy, contactUs, shippingPolicy, termsAndConditions } from "./constants/policies";
import BlogPage from "./pages/BlogPage";
import BlogView from "./pages/BlogView";
import AboutUs from "./pages/AboutUs";

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/faq" element={<FAQPage />} />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogView />} />
        <Route path="/terms-and-conditions" element={<PolicyWrapper data={termsAndConditions} />} />
        <Route path="/cancellion-and-refund-policy" element={<PolicyWrapper data={cancellationPolicy} />} />
        <Route path="/shipping-policy" element={<PolicyWrapper data={shippingPolicy} />} />
        <Route path="/contact-us" element={<PolicyWrapper data={contactUs} />} />
        <Route path="/about-us" element={<AboutUs />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
