import React from "react";
import { Link } from "react-router-dom";

const BlogCard = ({data}) => {
  return (
    <Link className="w-full flex flex-col gap-10 bg-white border-2 border-[#453C3C]" to={`/blog/${data.id}`}>
      <div className="relative">
        <img src={data.img} className="w-full h-56 object-cover" />
        {/* <div className="absolute text-center bottom-0 left-[50%] font-medium px-5 py-1 text-md md:text-lg -translate-x-[50%] translate-y-[50%] bg-[#D9D9D9]">
          {data.date}
        </div> */}
      </div>
      <div className="text-center font-medium text-xl pb-5">
        {data.title}
      </div>
    </Link>
  );
};

export default BlogCard;
