import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './swiper.css'

// import required modules
import { Autoplay, Navigation } from 'swiper/modules';
import BlogCard from './BlogCard';

export default function Carousel() {
    const [blogData, setBlogData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('/blog-data.json');
            const data = await response.json();
            setBlogData(data);
            console.log("carousel data:", data);
        }
        fetchData();
    }, [])

    return (
        <>
            {blogData.length > 0 && <>
                <div className='md:block hidden'>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        navigation={true}
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                        }}
                        modules={[Navigation, Autoplay]}
                        className="mySwiper blog-cr"
                    >
                        {blogData.map((data) => {
                            return <SwiperSlide><BlogCard data={data} /></SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <div className='md:hidden block'>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={30}
                        navigation={true}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                        }}
                        modules={[Navigation, Autoplay]}
                        className="mySwiper"
                    >
                        {blogData.map((data) => {
                            return <SwiperSlide><div className='w-11/12 mx-auto'><BlogCard data={data} /></div></SwiperSlide>
                        })}
                    </Swiper>
                </div>
            </>}
        </>
    );
}