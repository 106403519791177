import React from 'react'

const EmoteCard = ({text, image}) => {
  return (
    <div className='bg-[#fbbebe] w-full shadow-md'>
        <div className='bg-[#e38e8e] flex justify-center py-8 items-center shadow-md'>
            <img src={image} className='h-20 md:h-16'/>
        </div>
        <div className='flex px-10 font-medium justify-center w-full text-center items-center text-[#490c0c] text-2xl py-8 break-words'>
            {text}
        </div>
    </div>
  )
}

export default EmoteCard