import React from 'react'
import CollapsableFAQ from './Collapsable';

const FAQSection = ({data, head, inc, currentOpen, setCurrentOpen}) => {
    return (
        <div className='flex items-center flex-col'>
            <h2 className='w-full md:w-[90%] p-4 text-xl font-bold mt-6'>{head}</h2>
            <div className="content w-full md:w-[90%] flex flex-col gap-4 items-center p-4">
                {data.map((item, index) => {
                    return (
                        <CollapsableFAQ data={item} index={index + inc} open={currentOpen} changeOpen={setCurrentOpen} />
                    );
                })}
            </div>
        </div>
    )
}

export default FAQSection