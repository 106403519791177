import React from 'react'
import AboutSection from '../components/about/AboutSection'

const AboutUs = () => {
    return (
        <div className='bg-[#EBE7E7]' >
            <div className='pt-10'>
                <AboutSection head="Express, Feel, Connect" icon="/heart-br-1.png" data={[{ desc: "At Emote, we believe everyone deserves a safe space to express themselves freely, without fear of judgment. That’s why we created a platform where you can connect face-to-face (anonymously!) with verified listeners through call, chat, and video calls." }, { heading: "Our Mission", desc: "Our mission is to provide a sanctuary for individuals seeking emotional support. Emote is designed to help you navigate life's challenges by offering a secure environment where you can unmask your emotions and find compassionate support." }]} />
                <AboutSection head="Why Choose Emote?" icon="/faq.png" data={[{ heading: "Anonymity Guaranteed", desc: "Forget about usernames and avatars. In our anonymous environment, you can shed the masks and truly unmask your emotions. Share your deepest thoughts and feelings, explore vulnerabilities, and receive compassionate support, all while protecting your identity." }, { heading: "Verified Listeners", desc: "Our carefully selected listeners are experts in fostering active listening, non-judgmental support, and personalized guidance. Whether you’re navigating anxiety, stress, or simply need a listening ear, they’ll be there with you every step of the way." }, { heading: "Multiple Ways to Connect", desc: "Emote offers versatile communication options, including calls, chats, and video calls. Choose the method that suits you best and connect with a listener who understands and cares" }]} right={true} />
                <AboutSection head="Our Values" icon="/emote-para.png" data={[{ heading: "Compassion", desc: "We prioritize empathy and understanding in every interaction, ensuring you feel heard and supported." }, { heading: "Privacy", desc: "Your privacy is our top concern. We are committed to maintaining a secure and anonymous platform for all our users." }, { heading: "Support", desc: "From dealing with everyday stress to more significant emotional challenges, our listeners are here to offer unwavering support." }]} />
            </div>
            <div className={`flex flex-col md:px-28 px-8 py-16 gap-6`}>
                <h2 className='text-xl font-normal'>Join Us</h2>
                <h2 className='text-xl font-medium italic'>Join Us Unleash your true self. Find your voice. Find strength. This is Emote.</h2>
                <h2 className='text-xl font-normal'>Explore our platform, connect with compassionate listeners, and start your journey towards emotional well-being today.</h2>
                <div className='flex justify-center'>
                    <button className="text-lg font-medium border-2  border-[#D3C2C2] bg-white text-black w-fit rounded-2xl px-6 py-2 mt-6 md:mt-12">
                        App Coming Soon
                    </button>
                </div>
            </div>
        </div>
    )
}

export default AboutUs