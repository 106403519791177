import React from 'react'

const AboutSection = ({head, data, right, icon}) => {
    return (
        <div className={`grid grid-cols-1 md:grid-cols-4 px-8 md:px-28 py-8 md:py-16 border-b-2 gap-16 md:gap-28 border-[#453C3C80]`}>
            <div className='flex'>
                <img src={icon} className='mx-auto my-auto w-8/12 md:w-full' />
            </div>
            <div className={`md:col-span-3 flex flex-col gap-6 justify-center md:pr-20 ${right ? "md:order-first" : ""}`}>
                <h1 className='text-2xl font-bold'>{head}</h1>
                {data.map(item => <div className='gap-4  flex flex-col'>
                    {item.heading && <h2 className='text-xl font-semibold'>{item.heading}</h2>}
                    <p>{item.desc}</p>
                </div>)}
            </div>
        </div>
    )
}

export default AboutSection