import React from "react";

const EmotePara = () => {
  return (
    <div className="flex flex-col md:flex-row md:gap-24 my-16 md:pl-24 border-y-2 border-[#453C3C]" id="about">
      <div className=" w-full md:w-2/6 px-16 my-auto py-6 md:py-0">
        <img
          src="/emote-para.png"
          className="md:h-full w-8/12 md:object-cover object-contain mx-auto my-auto"
        />
      </div>
      <div className="w-full md:w-4/6 bg-[#453C3C] p-8 text-white flex flex-col gap-6">
        <span className="text-3xl">Emote</span>
        <p>
          At Emote, we believe everyone deserves a safe space to express
          themselves freely, without fear of judgment. That’s why we created a
          platform where you can connect face-to-face (anonymously!) with
          verified listeners through call, chat and video calls.
        </p>
        <p>
          Forget about usernames and avatars. In our anonymous environment, you
          can shed the masks and truly unmask your emotions. Share your deepest
          thoughts and feelings, explore vulnerabilities, and receive
          compassionate support, all while protecting your identity.
        </p>
        <p>
          Our carefully selected listeners are experts in fostering active
          listening, non-judgmental support, and personalized guidance. Whether
          you’re navigating anxiety, stress, or simply need a listening ear,
          they’ll be there with you every step of the way.
        </p>
        <p>
          Unleash your true self. Find your voice. Find strength. This is Emote.
        </p>
      </div>
    </div>
  );
};

export default EmotePara;
