import React from 'react'
import TextCarouselN from './textCR'


const Stats = () => {
    const statsList = [
        "Feeling down? You're not alone. 7 out of 10 people experience moments of sadness. Reach out today and find a supportive community that understands and cares.",
        "Struggling with anxiety? Nearly 8 out of 10 individuals feel the same. Join a group of empathetic peers and share your journey toward calm and peace.",
        "Dealing with stress? 9 out of 10 people feel overwhelmed at times. Connect with others who get it and find strategies to manage stress together.",
        "Need a friend? You're in good company. 6 out of 10 people crave deeper connections. Discover a network of kindred spirits and build meaningful relationships.",
        "Feeling isolated? You’re not the only one. 7 out of 10 people sometimes feel cut off from others. Engage with a community that offers support and a sense of belonging.",
        "Facing depression? You don’t have to go through it alone. 5 out of 10 people struggle with similar feelings. Find a compassionate community to share, heal, and grow together.",
        "Battling burnout? It’s more common than you think. 7 out of 10 people experience burnout. Connect with others to find balance and rejuvenate your spirit.",
        "Grieving a loss? 6 out of 10 people go through periods of grief. Share your story with those who understand and find comfort in a supportive network.",
        "Going through a breakup? You’re not by yourself. 8 out of 10 people experience the pain of a breakup. Connect with others who’ve been there and start your journey to healing.",
        "Coping with self-esteem issues? You’re in good company. 7 out of 10 people struggle with self-worth. Find a community that lifts you up and helps you rediscover your value."
    ]

    return (
        <>
            {/* <TextCarousel texts={statsList} /> */}
            <div className='w-full md:w-[90%] mx-auto'><TextCarouselN texts={statsList} /></div>
        </>
    )
}

export default Stats