import React from 'react'

const PolicyWrapper = ({ data }) => {
    return (
        <div className='md:py-14 py-10 md:px-32 px-10 min-h-[35vh]'>
            <h1 className='text-2xl font-bold mb-8'>{data.title}</h1>
            <p className='text-xl leading-snug' dangerouslySetInnerHTML={{ __html: data.desc }}></p>
        </div>
    )
}

export default PolicyWrapper