export const faqData=[
    {
      "question": "What is Emote?",
      "answer": "Emote is an anonymous emotional support platform where you can connect with verified listeners to talk about anything on your mind without revealing your identity."
    },
    {
      "question": "Is anonymity truly guaranteed?",
      "answer": "Yes, we take user privacy very seriously. Your conversations are encrypted, and your profile does not contain any identifying information. Listeners also cannot trace your identity."
    },
    {
      "question": "What makes Emote different?",
      "answer": "Emote prioritizes anonymity and confidentiality, ensuring a safe space for you to share openly without fear of judgment. Verified listeners offer empathetic support and guidance, while additional resources provide further tools for emotional well-being."
    },
    {
      "question": "Who can use Emote?",
      "answer": "Anyone seeking emotional support is welcome to use Emote. This includes individuals facing stress, anxiety, depression, relationship issues, or simply needing a listening ear."
    },
    {
      "question": "Is Emote a therapy platform?",
      "answer": "While Emote offers valuable support, it is not a substitute for professional therapy. If you require clinical diagnosis or treatment, please seek help from a qualified mental health professional."
    }
  ]
  
export const catFaq = {
  "gettingStarted": [
    {
      "question": "What is Emote?",
      "answer": "Emote is an anonymous emotional support platform where you can connect with verified listeners to talk about anything on your mind without revealing your identity."
    },
    {
      "question": "Is anonymity truly guaranteed?",
      "answer": "Yes, we take user privacy very seriously. Your conversations are encrypted, and your profile does not contain any identifying information. Listeners also cannot trace your identity."
    },
    {
      "question": "What makes Emote different?",
      "answer": "Emote prioritizes anonymity and confidentiality, ensuring a safe space for you to share openly without fear of judgment. Verified listeners offer empathetic support and guidance, while additional resources provide further tools for emotional well-being."
    },
    {
      "question": "Who can use Emote?",
      "answer": "Anyone seeking emotional support is welcome to use Emote. This includes individuals facing stress, anxiety, depression, relationship issues, or simply needing a listening ear."
    },
    {
      "question": "Is Emote a therapy platform?",
      "answer": "While Emote offers valuable support, it is not a substitute for professional therapy. If you require clinical diagnosis or treatment, please seek help from a qualified mental health professional."
    },
    {
      "question": "Are the listeners qualified?",
      "answer": "Absolutely! All listeners go through a rigorous verification process that assesses their empathy, communication skills, and understanding of mental health. We only accept the most qualified and compassionate individuals."
    }
  ],
  "useEmote": [
    {
      "question": "How do I connect with a listener?",
      "answer": "You can either browse profiles with brief bios or use our matching algorithm to connect you with a listener based on your needs and preferences."
    },
    {
      "question": "Can I choose the topic of conversation?",
      "answer": "Yes, you can talk about anything you want, from everyday struggles to deeper emotional concerns. The listeners are there to offer a safe space for you to express yourself freely."
    },
    {
      "question": "What if I'm uncomfortable with a listener?",
      "answer": "You can easily disconnect from any conversation and connect with another listener without explanation."
    },
    {
      "question": "Can I stay anonymous even after multiple sessions with the same listener?",
      "answer": "Yes, your anonymity is always protected, even if you connect with the same listener repeatedly."
    },
    {
      "question": "Is there a limit to how often I can use Emote?",
      "answer": "You can use Emote as often as you need it. There are no limits on the number of conversations or the duration of each session."
    }
  ],
  "privacy": [
    {
      "question": "What happens to my conversations?",
      "answer": "Your conversations are stored securely and confidentially."
    },
    {
      "question": "What if I encounter someone inappropriate on the platform?",
      "answer": "We have strict policies in place to prevent misuse of the platform. If you experience any inappropriate behavior, please report it immediately using the built-in reporting tool."
    },
    {
      "question": "How can I contact support?",
      "answer": "You can reach our support team through the app or by visiting our website."
    }
  ]
}
