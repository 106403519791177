import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {  Autoplay, Navigation } from 'swiper/modules';
import './carousel.css'

export default function TextCarouselN({ texts }) {
    return (
        <>
            <Swiper
                effect='fade'
                fadeEffect={{crossFade: true}}
                navigation={true}
                modules={[Navigation, Autoplay]}
                autoplay={{
                    delay: 3000,
                }}
                className="mySwiper textSwiper"
                loop={true}
                injectStyles={[
                    `   
                        .swiper-button-prev {
                            color: #AB1D1D;
                            margin-left: 20px;
                            font-weight: bold;
                        }
                    `
                ]}
            >
                {texts.map( text => <SwiperSlide>
                    <div className='flex justify-center py-12'>
                        <div className='w-10/12 md:w-9/12 bg-[#AB1D1D] py-3 px-5 rounded-xl mx-10'>
                            <h5 className='text-white text-md text-center' dangerouslySetInnerHTML={{ __html: text }}></h5>
                        </div>
                    </div>
                </SwiperSlide>)}
            </Swiper>
        </>
    );
}
