import React, { useEffect, useState } from 'react'
import { BallTriangle } from 'react-loader-spinner';
import { useParams } from 'react-router-dom';

const BlogView = () => {
    const [blogDesc, setBlogDesc] = useState();
    const [isLoading, setIsLoading] = useState(true);
    let { id } = useParams();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            const response = await fetch(`/blog-desc/${id}.json`);
            const data = await response.json();
            setBlogDesc(data.desc)
            setIsLoading(false);
        }
        fetchData()
    }, [id])

    if (isLoading) {
        return (
            <div className='md:py-14 py-10 md:px-32 px-10 min-h-[50vh] flex justify-center items-center' >
                <BallTriangle
                    height={100}
                    width={100}
                    radius={5}
                    color="#453C3C"
                    ariaLabel="ball-triangle-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            </div>
        )
    }

    return (
        <div className='md:py-14 py-10 md:px-32 px-10 min-h-[35vh]'>
            {blogDesc && <div dangerouslySetInnerHTML={{ __html: blogDesc }}></div>}
        </div>
    )
}

export default BlogView