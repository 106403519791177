import React, { useEffect, useState } from 'react'
import { catFaq } from '../constants/faq'
import FAQSection from '../components/faq/FAQSection'

const FAQPage = () => {
    const [currentOpen, setCurrentOpen] = useState(null);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className='pb-12 bg-[#EBE7E7]'>
            <FAQSection data={catFaq.gettingStarted} inc={0} head="Before You Get Started" currentOpen={currentOpen} setCurrentOpen={setCurrentOpen} />
            <FAQSection data={catFaq.useEmote} inc={catFaq.gettingStarted.length} head="Using Emote" currentOpen={currentOpen} setCurrentOpen={setCurrentOpen} />
            <FAQSection data={catFaq.privacy} inc={catFaq.gettingStarted.length + catFaq.useEmote.length} head="Privacy and Safety" currentOpen={currentOpen} setCurrentOpen={setCurrentOpen} />
        </div>
    )
}

export default FAQPage