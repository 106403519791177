import React from 'react'
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const CollapsableFAQ = ({ index, open, changeOpen, data }) => {
    return (
        <div key={index} onClick={() => { index === open ? open === null ? changeOpen(index) : changeOpen(null) :  changeOpen(index)}} className='w-full rounded-md overflow-hidden'>
            <div className='bg-[#453C3C] px-4 py-5 w-full flex items-center justify-between cursor-pointer'>
                <h2 className='text-white text-lg'>{data.question}</h2>
                {index === open ? <FaAngleUp color='white' size={30} /> : <FaAngleDown color='white' size={30} />}
            </div>
            {open === index && <div className='bg-[#5D5B5B] px-4 py-5 w-full'>
                <p className='text-white text-md'>{data.answer}</p>
            </div>}
        </div>
    )
}

export default CollapsableFAQ